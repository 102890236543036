import {
    useCallback,
    useState,
    useEffect,
    useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    Form,
    Row,
    Col,
    DatePicker,
    Select,
    Button,
    Space,
    Divider,
    Popover,
} from 'antd';

import IncrementNumberInput from '@/components/IncrementNumberInput';
import { CalendarIcon, DownIcon } from '@/assets/images/icons/screens';
import { dateFormat } from '@/helpers/dateFormat';
import { actions as CruiseActions } from '@/redux/tour/cruise/view/reducer';
import { actions as AccommodationActions } from '@/redux/accommodation/hotel/view/reducer';
import { actions as PackagesActions } from '@/redux/tour/packages/view/reducer';
import { actions as maxPriceActions } from '@/redux/maxprice/reducer';

import PriceSlider from './PriceSlider';

const Filters = ({
    type,
    dateStartEnd,
    setDateStartEnd,
    roomTypeList,
    cabinTypeList,
    iteneraryStartDates,
    iteneraryEndDates,
    setCruiseStartDate,
    setCruiseEndDate,
}) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const [adultNumber, setAdultNumber] = useState(2);
    const [childNumber, setChildNumber] = useState(0);
    const [infantNumber, setInfantNumber] = useState(0);

    const accommodationMaxPrice = useSelector((state) => state.maxprice.accommodationMaxPrice);
    const tourMaxPrice = useSelector((state) => state.maxprice.tourMaxPrice);

    useEffect(() => {
        if (type === 'Hotel') {
            dispatch(maxPriceActions.requestGetMaxPrice({ type: 2 }));
        } else {
            dispatch(maxPriceActions.requestGetMaxPrice({ type: 1 }));
        }
    }, [type]);

    const roomOptions = roomTypeList?.map((room) => (
        <Select.Option
            key={room.id}
            value={room.id}
        >
            {room.type_name}
        </Select.Option>
    ));

    const cabinTypeOptions = cabinTypeList?.map((cabin) => (
        <Select.Option
            key={cabin.id}
            value={cabin.id}
        >
            {cabin.type}
        </Select.Option>
    ));

    const onSearch = useCallback((values) => {
        const data = {
            adults: values.adults === undefined ? 2 : values.adults,
            children: values.children,
            infants: values.infants,
            min_price: values.price?.[0],
            max_price: values.price?.[1],
        };
        const cabinFilterData = {
            ...data,
            cabin_types: values.cabin_types,
        };
        const roomFilterData = {
            ...data,
            date_start: values.date?.[0]?.format(dateFormat),
            date_end: values.date?.[1]?.format(dateFormat),
            room_types: values.room_types,
        };

        if (type === 'Tour Package') {
            dispatch(PackagesActions.savePackageFilterData(roomFilterData));
        } else if (type === 'Tour Cruise') {
            dispatch(CruiseActions.saveCruiseFilterData(cabinFilterData));
        } else {
            dispatch(AccommodationActions.saveRoomFilterData(roomFilterData));
        }
    }, [dispatch, type]);

    const initialData = useMemo(() => {
        if (accommodationMaxPrice || tourMaxPrice) {
            return {
                date: dateStartEnd,
                price: [10, type === 'Hotel' ? accommodationMaxPrice : tourMaxPrice],
            };
        } else {
            return {
                date: dateStartEnd,
            };
        }
    }, [accommodationMaxPrice, tourMaxPrice, type]);

    if (!initialData.price) {
        return 'loading';
    }

    return (
        <div className="search-form">
            <Row gutter={30}>
                <Col flex="auto">
                    <Form
                        onFinish={onSearch}
                        form={form}
                        initialValues={initialData}
                    >
                        <Row justify="space-between" align="middle">
                            <Col>
                                <Space
                                    size={10}
                                    align="start"
                                    split={(<Divider type="vertical" />)}
                                >
                                    <div>
                                        {type === 'Tour Cruise' ? (
                                            <div className="cabin-type_selection">
                                                <div className="search-block_title">
                                                    {t('Cabin type')}
                                                </div>

                                                <Form.Item name="cabin_types" noStyle>
                                                    <Select mode="multiple">
                                                        {cabinTypeOptions}
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        ) : (
                                            <div>
                                                <div className="date-range">
                                                    {type === 'Hotel' ? t('Check in - Check out') : t('Date range')}
                                                </div>

                                                <Form.Item name="date" noStyle>
                                                    <DatePicker.RangePicker
                                                        popupClassName="filter-calendar"
                                                        suffixIcon={<CalendarIcon />}
                                                        onChange={(dates) => setDateStartEnd(dates)}
                                                        getPopupContainer={(trigger) => {
                                                            return trigger?.parentElement;
                                                        }}
                                                        separator="-"
                                                        allowClear={false}
                                                    />
                                                </Form.Item>
                                            </div>
                                        )}
                                    </div>
                                    <div>
                                        <div>
                                            <Popover
                                                trigger="click"
                                                placement="bottom"
                                                getPopupContainer={(trigger) => {
                                                    return trigger?.parentElement;
                                                }}
                                                overlayClassName="reservation-guests_dropdown"
                                                content={(
                                                    <div className="reservation-guests_dropdown_content">
                                                        <div>
                                                            <Space size={35}>
                                                                <span>{t('Adults')}</span>
                                                                <Form.Item name="adults" noStyle>
                                                                    <IncrementNumberInput
                                                                        hasInputBorder={false}
                                                                        onChange={setAdultNumber}
                                                                        defaultCount={adultNumber}
                                                                    />
                                                                </Form.Item>
                                                            </Space>
                                                        </div>
                                                        <div>
                                                            <Space size={20}>
                                                                <span>{t('Children')}</span>
                                                                <Form.Item name="children" noStyle>
                                                                    <IncrementNumberInput
                                                                        hasInputBorder={false}
                                                                        onChange={setChildNumber}
                                                                        defaultCount={childNumber}
                                                                    />
                                                                </Form.Item>
                                                            </Space>
                                                        </div>
                                                        <div>
                                                            <Space size={30}>
                                                                <span>{t('Infants')}</span>
                                                                <Form.Item name="infants" noStyle>
                                                                    <IncrementNumberInput
                                                                        hasInputBorder={false}
                                                                        onChange={setInfantNumber}
                                                                        defaultCount={infantNumber}
                                                                    />
                                                                </Form.Item>
                                                            </Space>
                                                        </div>
                                                    </div>
                                                )}
                                            >
                                                <div className="guests_filters">
                                                    <Button>
                                                        <div className="filter-guest_title">
                                                            {t('Guests')}
                                                            <DownIcon />
                                                        </div>
                                                    </Button>
                                                </div>
                                            </Popover>
                                        </div>
                                        <div className="guests-options_picked">
                                            {adultNumber !== 0 && adultNumber !== undefined && (
                                                `${adultNumber} ${(adultNumber === 1) ? `${t('Adult')}, ` : `${t('Adults')}, `}`
                                            )}
                                            {childNumber !== 0 && childNumber !== undefined && (
                                                `${childNumber} ${childNumber === 1
                                                    ? (infantNumber !== 0 && infantNumber !== undefined) ? `${t('Child')}, ` : `${t('Child')} `
                                                    : (infantNumber !== 0 && infantNumber !== undefined) ? `${t('Children')}, ` : `${t('Children')} `}`
                                            )}
                                            {infantNumber !== 0 && infantNumber !== undefined && (
                                                `${infantNumber} ${(infantNumber === 1) ? t('Infant') : t('Infants')}`
                                            )}
                                        </div>
                                    </div>

                                    {type !== 'Tour Cruise' && (
                                        <div className="room-type_filters">
                                            <div>
                                                {t('Room type')}
                                            </div>

                                            <Form.Item name="room_types" noStyle>
                                                <Select
                                                    mode="multiple"
                                                    getPopupContainer={(trigger) => {
                                                        return trigger?.parentElement;
                                                    }}
                                                >
                                                    {roomOptions}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    )}

                                    {type === 'Tour Cruise' && (
                                        <>
                                            <div className="cabin-date_selection">
                                                <div className="search-block_title">
                                                    {t('Start date')}
                                                </div>
                                                <Form.Item name="start_date">
                                                    <Select
                                                        onChange={(value) => {
                                                            setCruiseStartDate(value);
                                                        }}
                                                    >
                                                        {iteneraryStartDates.map((date, i) => (
                                                            <Select.Option
                                                                key={i.toString()}
                                                                value={date}
                                                            >
                                                                {date}
                                                            </Select.Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                            <div className="cabin-date_selection">
                                                <div className="search-block_title">
                                                    {t('End date')}
                                                </div>
                                                <Form.Item name="end_date">
                                                    <Select
                                                        onChange={(value) => {
                                                            setCruiseEndDate(value);
                                                        }}
                                                    >
                                                        {iteneraryEndDates.map((date, i) => (
                                                            <Select.Option
                                                                key={i.toString()}
                                                                value={date}
                                                            >
                                                                {date}
                                                            </Select.Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </>
                                    )}

                                    <div>
                                        <PriceSlider
                                            type={type}
                                            accommodationMaxPrice={accommodationMaxPrice}
                                            tourMaxPrice={tourMaxPrice}
                                        />
                                    </div>
                                </Space>
                            </Col>

                            <Col>
                                <Button
                                    className="search-button"
                                    type="text"
                                    onClick={() => form.submit()}
                                >
                                    {type === 'Hotel' ? t('Check availability').toUpperCase() : t('Search').toUpperCase()}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </div>
    );
};

export default Filters;
