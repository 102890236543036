import {
    useState,
    useMemo,
    useEffect,
    useCallback,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Radio } from 'antd';

import Header from '@/components/ViewOneHeader';
import { selectors } from '@/redux/tour/packages/view/model';
import { actions } from '@/redux/tour/packages/view/reducer';
import { getSubPageByHash } from '@/helpers/routing';
import ProductCollection from '@/components/ProductCollection';

import TourInfo from './TourInfo';
import Reservation from './Reservation';

const subPages = [
    { name: 'Tour Info', hash: 'tour-info' },
    { name: 'Reservation', hash: 'reservation' },
];

const ViewOne = ({ viewFromB2b = false }) => {
    const { t } = useTranslation();
    const { id } = useParams();
    const dispatch = useDispatch();
    const {
        location: { hash },
        push,
    } = useHistory();

    const loading = useSelector(selectors.loading);
    const packageDetails = useSelector(selectors.packageDetails);
    const filters = useSelector(selectors.filterData);
    const tourMaxPrice = useSelector((state) => state.maxprice.tourMaxPrice);
    const { status: serviceStatus } = packageDetails !== null && packageDetails;
    const { b2b_agency_name: b2bAgency } = packageDetails !== null && packageDetails;

    const myService = b2bAgency === null; // if b2bAgency is null, it's my service

    const [visibleDetails, setVisibleDetails] = useState(
        () => getSubPageByHash(subPages, hash).hash,
    );

    const options = useMemo(
        () => subPages.map((page) => ({
            label: t(page.name),
            value: page.hash,
        })),
        [t],
    );

    const ViewDetailsComponent = useMemo(() => {
        switch (visibleDetails) {
            default:
            case subPages[0].hash:
                return TourInfo;
            case subPages[1].hash:
                return Reservation;
        }
    }, [visibleDetails]);

    useEffect(() => {
        if (!viewFromB2b) {
            push(`/app/tours/packages/${id}#${visibleDetails}`);
        } else {
            push(`/app/b2b-partners/worldwide-supplier/package/${id}#${visibleDetails}`);
        }
    }, [push, visibleDetails, id, viewFromB2b]);

    const [page, setPage] = useState(1);

    const handleFetchPackageRooms = useCallback(
        ({ perPage = 10, currentPage = 1, fetchMore }) => {
            let data = null;
            if (filters) {
                data = {
                    ...filters,
                    page: currentPage,
                    // eslint-disable-next-line object-shorthand
                    perPage: perPage,
                };
            } else {
                data = {
                    page: currentPage,
                    // eslint-disable-next-line object-shorthand
                    perPage: perPage,
                    adults: 2,
                    min_price: 10,
                    max_price: tourMaxPrice,
                };
            }
            tourMaxPrice !== null
            && dispatch(actions.requestFetchPackageRooms(id, data, fetchMore));
            setPage(currentPage + 1);
        },
        [
            dispatch,
            id,
            filters,
            tourMaxPrice,
        ],
    );

    useEffect(() => {
        dispatch(
            actions.requestViewPackage(id),
        );
    }, [dispatch, id]);

    useEffect(() => {
        handleFetchPackageRooms({});
    }, [handleFetchPackageRooms]);

    // fetch more rooms
    const handleFetchMore = useCallback(() => {
        handleFetchPackageRooms({ currentPage: page, fetchMore: true });
    }, [handleFetchPackageRooms, page]);

    return packageDetails === null || loading ? (
        'loading'
    ) : (
        <div id="view-tour-package-page">
            {!myService
            && (serviceStatus === null || serviceStatus === '2')
            && (
                <ProductCollection
                    data={packageDetails}
                    serviceStatus={serviceStatus}
                    serviceId={id}
                />
            )}
            <Header
                goBackTitle={myService ? 'View all Package Tours' : 'Go back'}
                goBackUrl={myService ? '/app/tours/packages' : '/app/b2b-partners/worldwide-supplier'}
                title={packageDetails.name}
            >
                {((myService) || (!myService && serviceStatus === '1')) && (
                    <Radio.Group
                        optionType="button"
                        buttonStyle="solid"
                        options={options}
                        onChange={(e) => setVisibleDetails(e.target.value)}
                        value={visibleDetails}
                    />
                )}
            </Header>

            <div className="view-content">
                <ViewDetailsComponent
                    myService={myService}
                    fetchMore={handleFetchMore}
                />
            </div>
        </div>
    );
};

export default ViewOne;
