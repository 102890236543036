import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    Form,
    Row,
    Col,
    Button,
    DatePicker,
    Space,
    Divider,
    Popover,
} from 'antd';
import { DownIcon, PlusIcon } from '@/assets/images/icons/screens';
import { dateFormat } from '@/helpers/dateFormat';
import IncrementNumberInput from '@/components/IncrementNumberInput';
import { actions } from '@/redux/tour/trip/view/reducer';

const Filters = ({
    onAddClick,
    adultNumber,
    setAdultNumber,
    childNumber,
    setChildNumber,
    infantNumber,
    setInfantNumber,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const onSearch = useCallback((values) => {
        const data = {
            adults: values.adults === undefined ? 2 : values.adults,
            children: values.children,
            infants: values.infants,
            date_start: values.date?.[0]?.format(dateFormat),
            date_end: values.date?.[1]?.format(dateFormat),
        };
        dispatch(actions.saveTripFilterData(data));
    }, [dispatch]);

    return (
        <div className="search-form">
            <Row gutter={30}>
                <Col flex="auto">
                    <Form
                        onFinish={onSearch}
                        form={form}
                    >
                        <Row justify="space-between" align="middle">
                            <Col>
                                <Space
                                    size={25}
                                    align="start"
                                    split={(<Divider type="vertical" />)}
                                >
                                    <div>
                                        <div className="date-title">
                                            {t('Date range')}
                                        </div>

                                        <Form.Item name="date" noStyle>
                                            <DatePicker.RangePicker
                                                popupClassName="filter-calendar"
                                                separator="-"
                                                placeholder={[t('Start date'), t('End date')]}
                                            />
                                        </Form.Item>
                                    </div>

                                    <div>
                                        <div>
                                            <Popover
                                                trigger="click"
                                                placement="bottom"
                                                getPopupContainer={(trigger) => {
                                                    return trigger?.parentElement;
                                                }}
                                                className="reservation-guests_dropdown"
                                                content={(
                                                    <div className="reservation-guests_dropdown_content">
                                                        <div>
                                                            <Space size={35}>
                                                                <span>{t('Adults')}</span>
                                                                <Form.Item name="adults" noStyle>
                                                                    <IncrementNumberInput
                                                                        hasInputBorder={false}
                                                                        onChange={setAdultNumber}
                                                                        defaultCount={adultNumber}
                                                                    />
                                                                </Form.Item>
                                                            </Space>
                                                        </div>
                                                        <div>
                                                            <Space size={20}>
                                                                <span>{t('Children')}</span>
                                                                <Form.Item name="children" noStyle>
                                                                    <IncrementNumberInput
                                                                        hasInputBorder={false}
                                                                        onChange={setChildNumber}
                                                                        defaultCount={childNumber}
                                                                    />
                                                                </Form.Item>
                                                            </Space>
                                                        </div>
                                                        <div>
                                                            <Space size={30}>
                                                                <span>{t('Infants')}</span>
                                                                <Form.Item name="infants" noStyle>
                                                                    <IncrementNumberInput
                                                                        hasInputBorder={false}
                                                                        onChange={setInfantNumber}
                                                                        defaultCount={infantNumber}
                                                                    />
                                                                </Form.Item>
                                                            </Space>
                                                        </div>
                                                    </div>
                                                )}
                                            >
                                                <Button>
                                                    <div className="filter-guest_title">
                                                        {t('Guests')}
                                                        <DownIcon />
                                                    </div>
                                                </Button>
                                            </Popover>
                                        </div>
                                        <div className="guests-options_picked">
                                            {adultNumber !== 0 && adultNumber !== undefined && (
                                                `${adultNumber} ${(adultNumber === 1) ? `${t('Adult')},` : `${t('Adults')},`}`
                                            )}
                                            {childNumber !== 0 && childNumber !== undefined && (
                                                `${childNumber} ${(childNumber === 1) ? `${t('Child')},` : `${t('Children')},`}`
                                            )}
                                            {infantNumber !== 0 && infantNumber !== undefined && (
                                                `${infantNumber} ${(infantNumber === 1) ? `${t('Infant')},` : `${t('Infants')},`}`
                                            )}
                                        </div>
                                    </div>
                                </Space>
                            </Col>

                        </Row>
                    </Form>
                </Col>

                <Col flex="none" className="add-button_col">
                    <Col>
                        <Button
                            className="search-button"
                            type="text"
                            onClick={() => form.submit()}
                        >
                            {t('Search').toUpperCase()}
                        </Button>
                    </Col>
                    <Button
                        className="add-group-button "
                        onClick={onAddClick}
                    >
                        <PlusIcon />
                        {' '}
                        {t('Add new group').toUpperCase()}
                    </Button>
                </Col>
            </Row>
        </div>
    );
};

export default Filters;
