import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Waypoint } from 'react-waypoint';
import Filters from '@/components/RerservationFilters';
import ReservationList from '@/components/ReservationList';
import { selectors } from '@/redux/tour/packages/view/model';

const Reservation = ({ fetchMore }) => {
    const [roomListSelected, setRoomListSelected] = useState([]);
    const [dateStartEnd, setDateStartEnd] = useState([]);

    const roomList = useSelector(selectors.roomList);
    const roomTypeList = useSelector(selectors.roomTypeList);
    const { id } = useSelector(selectors.packageDetails);
    const isLoadingMore = useSelector(selectors.isLoadingMore);
    const hasMore = useSelector(selectors.hasMore);

    return (
        <>
            <Filters
                type="Tour Package"
                dateStartEnd={dateStartEnd}
                setDateStartEnd={setDateStartEnd}
                roomTypeList={roomTypeList}
            />
            <ReservationList
                roomListSelected={roomListSelected}
                setRoomListSelected={setRoomListSelected}
                reservationList={roomList}
                dateStartEnd={dateStartEnd}
                type="Tour Package"
                tourId={id}
            />
            {!isLoadingMore && hasMore && (
                <Waypoint onEnter={fetchMore} />
            )}
        </>
    );
};

export default Reservation;
