import { useTranslation } from 'react-i18next';
import {
    Divider,
    Card,
    Avatar,
    Button,
    Space,
    notification,
} from 'antd';
import { dateFormat } from '@/helpers/dateFormat';
import { actions } from '@/redux/invoice/create/reducer';
import { removeUnnecessaryValues } from '../removeValues';

const RoomList = ({
    roomListSelected,
    dateStartEnd,
    replace,
    dispatch,
    notificationKey,
    type,
    tourId,
    currency,
}) => {
    const { t } = useTranslation();

    // prepare data for reservation
    let reservationData = null;
    const keysToRemove = [
        'total_price',
        'room_type_total_price',
        'extra_beds_total_price',
        'room_img',
        'price_type',
    ];

    if (type === 'Hotel') {
        reservationData = {
            reservation_type: 'Hotel',
            tour_id: tourId,
            check_in: dateStartEnd[0]?.format(dateFormat),
            check_out: dateStartEnd[1]?.format(dateFormat),
            currency_icon: currency,
            data: removeUnnecessaryValues(roomListSelected, keysToRemove),
        };
    } else {
        reservationData = {
            reservation_type: 'Tour Package',
            tour_id: tourId,
            date_start: dateStartEnd[0]?.format(dateFormat),
            date_end: dateStartEnd[1]?.format(dateFormat),
            currency_icon: currency,
            data: removeUnnecessaryValues(roomListSelected, keysToRemove.concat(['extra_beds'])),
        };
    }

    const bookNow = () => {
        dispatch(actions.clearInvoiceState());
        dispatch(actions.prepareReservationDataForSubmit(reservationData));
        if (type === 'Hotel') {
            dispatch(actions.requestGetReservationData(
                tourId,
                {
                    type: 0,
                    check_in: dateStartEnd[0]?.format(dateFormat),
                    check_out: dateStartEnd[1]?.format(dateFormat),
                    data: removeUnnecessaryValues(roomListSelected, keysToRemove),
                },
            ));
        } else {
            dispatch(actions.requestGetReservationData(
                tourId,
                {
                    type: 2,
                    data: removeUnnecessaryValues(roomListSelected, keysToRemove.concat(['extra_beds'])),
                },
            ));
        }
        replace('/app/invoices/new');
        setTimeout(() => {
            notification.close(notificationKey);
        }, 1000);
    };
    return (
        <>
            <Divider />
            {roomListSelected.map((room) => (
                <div key={room.room_type}>
                    <Card bordered={false}>
                        <Card.Meta
                            avatar={<Avatar src={room.room_img} />}
                            title={(
                                <Space size={65}>
                                    <span className="room-type_name">{t(room.type_name)}</span>
                                    {type === 'Hotel' ? (
                                        <span>
                                            {`${room.price_type}${room.room_type_total_price}
                                            + ${room.extra_beds_total_price}`}
                                        </span>
                                    ) : (
                                        <span className="room-type_total_price">
                                            {room.price_type}
                                            {room.room_type_total_price}
                                        </span>
                                    )}
                                </Space>
                            )}
                            description={(
                                <div className="selected-details">
                                    <div>
                                        {`${t('Number of Rooms')}: ${room.no_rooms}`}
                                    </div>
                                    {type === 'Hotel' && (
                                        <div>
                                            {`${t('Number of Extra Beds')}: ${room.extra_beds}`}
                                        </div>
                                    )}
                                </div>
                            )}
                        />
                    </Card>
                    <Divider dashed />
                </div>
            ))}
            <div>
                <Button
                    block
                    onClick={bookNow}
                >
                    {t('Book now').toUpperCase()}
                </Button>
            </div>
        </>
    );
};

export default RoomList;
